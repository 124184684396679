/*=============================================
=            About Us            =
=============================================*/

.about-us-content {
  &__small-title {
    font-size: 2em;
    align-items: center;
    font-weight: 700;
    line-height: 1.5;
    color: $theme-color--black4;
    margin-bottom: 25px;
    margin-top: 35px;
    border-bottom: 7px solid $theme-color--default;
    padding-top: 1px;
    display: inline-flex;
  }
}

/*=====  End of video cta  ======*/
.university-container {
  display: flex;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  border-radius: 8px;
  padding-top: 50px;
  margin: 5px;
  margin-bottom: 80px;
}

.image-container {
  flex: 1;
  margin-right: 5px;
}

.university-imagie {
  width: 250px;
  max-width: 250px; /* Adjust the max-width as needed */
  border-radius: 20px;
}

.description-container {
  flex: 2;
}

.principal-statement {
  font-size: 1.3rem;
  margin-bottom: 20px;
  text-align: justify;
}

.read-more-btn {
  background-color: $theme-color--default;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.read-more-btn:hover {
  background-color: $theme-color--black4;
}
img {
  float: left; /* Float the image to the left */
  margin-right: 10px; /* Add some margin to separate the image from the text */
}
@media (max-width: 768px) {
  /* Adjust the max-width as needed */
  img {
    float: none; /* Remove floating */
    display: block; /* Ensure the image is displayed as a block element */
    margin: 0 auto; /* Center the image horizontally */
    margin-bottom: 10px; /* Add some margin at the bottom */
  }
}
.read-or-hide {
  color: rgb(192, 192, 192);
  cursor: pointer;
}
