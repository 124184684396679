.team-member-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Adjust the space between images as needed */
  justify-content: center;
}

.team-member-wrapper > div {
  flex: 0 0 80px; /* Fixed width of 200px for each item */
  border-bottom: 5px solid #0d6526; /* Adjust the color and thickness as needed */
}

.team-member-wrapper > div:nth-child(1) {
  flex-basis: calc(100% - 50%); /* Full width for the first item */
}

.team-member-wrapper > div:nth-child(2),
.team-member-wrapper > div:nth-child(3) {
  flex-basis: calc(50% - 10px); /* Two items on the second row */
  width: 120px;
}

.team-member-wrapper > div:nth-child(n + 4) {
  flex-basis: calc(33.33% - 13.33px); /* Three items on the subsequent rows */
}
.team .image img {
  width: 150px; /* Fixed width for the images */
  height: 150px; /* Fixed height for the images */
  object-fit: cover; /* Ensure images maintain aspect ratio and cover the area */
  border-bottom: 2px solid #0d6526; /* Adjust the color and thickness as needed */
}
␛ .team {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px; /* Add padding to the bottom to create space for the border */
  border-bottom: 2px solid #0d6526; /* Adjust the color and thickness as needed */
}
.view-profile {
  display: inline-block;
  margin-top: 5px;
  padding: 3px 20px;
  font-size: 18px;
  color: #fff;
  background-color: #0d6526; /* Adjust the color as needed */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.view-profile:hover {
  background-color: #007bff; /* Adjust the hover color as needed */
}
