/*=============================================
=           organization structure           =
=============================================*/
/* Chart.css */

.vision-container {
  font-family: Arial, sans-serif;
}

.content-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 20px; /* Adjust padding for smaller screens */
  background-color: #f4f4f4;
}

/* Adjust styles for medium screens (>= 768px) */
@media (min-width: 768px) {
  .content-section {
    padding: 20px 40px; /* Increase padding for medium screens */
  }
}

/* Adjust styles for larger screens (>= 992px) */
@media (min-width: 992px) {
  .content-section {
    padding: 20px 80px; /* Increase padding for large screens */
  }
}

.section {
  margin-bottom: 40px;
}
