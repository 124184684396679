/*=============================================
=            marquee            =
=============================================*/
.marquee {
  width: 100%;
  overflow: hidden;
  background-color: #89cff0;
  white-space: nowrap;
  box-sizing: border-box;
  color: red;
  font-size: 25px;
  font-weight: bold;
}

.marquee p {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 15s linear infinite;
}
/* Stop the marquee on hover */
.marquee:hover p {
  animation-play-state: paused; /* Stop the animation */
}
@media (max-width: 768px) {
  /* Adjust the max-width as needed */
  .marquee {
    float: none; /* Remove floating */
    display: block; /* Ensure the image is displayed as a block element */
    margin: 0 auto; /* Center the image horizontally */
    margin-bottom: 10px; /* Add some margin at the bottom */
    background-color: #0d6526;
  }
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
