.board-members {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

.board-row {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.board-member {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.member-image {
  width: 170px;
  height: 170px;
  border-radius: 5%;
  object-fit: cover;
  margin-bottom: 10px;
}

.member-info {
  text-align: center;
}
