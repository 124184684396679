/*=============================================
=            history and major roles            =
=============================================*/

.vision-container {
  font-family: Arial, sans-serif;
}

.breadcrumb-area {
  padding: 20px 0;
  background-size: cover;
  background-position: center;
  color: white;
}

.page-banner h1 {
  margin: 0;
  font-size: 36px;
  font-weight: bold;
}

.page-breadcrumb {
  list-style: none;
  padding: 0;
  margin: 10px 0 0;
}

.page-breadcrumb li {
  display: inline;
  font-size: 18px;
}

.page-breadcrumb li a {
  color: white;
  text-decoration: none;
}

.content-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 240px;
  background-color: #f4f4f4;
}

.section {
  margin-bottom: 40px;
}

.section h2 {
  font-size: 28px;
  color: #333;
  border-bottom: 2px solid #0d6526;
  padding-bottom: 10px;
  margin-bottom: 20px;
  text-align: center;
}

.section p {
  font-size: 18px;
  line-height: 1.6;
  color: #555;
  text-align: justify;
}

.core-value-item {
  margin-bottom: 20px;
  padding: 20px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.core-value-item h3 {
  font-size: 24px;
  color: #0d6526;
  margin-bottom: 10px;
}

.core-value-item p {
  font-size: 16px;
  color: #666;
}
